/* ==========================================================================
   Base Animations
   ========================================================================== */

   @keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  
  @keyframes float {
    0% { transform: translateY(0px) rotate(0deg); }
    50% { transform: translateY(-20px) rotate(5deg); }
    100% { transform: translateY(0px) rotate(0deg); }
  }
  
  @keyframes gradient-x {
    0%, 100% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
  }
  
  @keyframes rotate-360 {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  @keyframes particle {
    0% { 
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      opacity: 1;
      transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y))) scale(0.5);
    }
    100% {
      opacity: 0;
      transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y))) scale(0);
    }
  }
  
  @keyframes gradient-text {
    0%, 100% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
  }
  
  @keyframes borderGradient {
    0%, 100% { background-position: 0% 0%; }
    50% { background-position: 100% 100%; }
  }
  
  @keyframes pulse {
    0%, 100% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.8; transform: scale(1.05); }
  }
  
  /* ==========================================================================
     Animation Utility Classes
     ========================================================================== */
  
  .animate-fade-in {
    animation: fadeIn 0.5s ease-out forwards;
  }
  
  .floating {
    animation: float 6s ease-in-out infinite;
  }
  
  .animate-gradient-x {
    background-size: 200% 200%;
    animation: gradient-x 15s ease infinite;
  }
  
  .rotate-360 {
    animation: rotate-360 1s ease-in-out;
  }
  
  .animate-particle {
    animation: particle 0.8s ease-out infinite;
  }
  
  .pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  
  /* ==========================================================================
     Visual Effects
     ========================================================================== */
  
  /* Background Patterns */
  .bg-grid-white {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
    mask-image: linear-gradient(to bottom, transparent, black, transparent);
  }
  
  /* Glass Effects */
  .glass {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .glass-card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  }
  
  /* Text Effects */
  .gradient-text {
    background: linear-gradient(to right, #818cf8, #c084fc, #818cf8);
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradient-text 8s linear infinite;
  }
  
  .neon {
    text-shadow: 
      0 0 5px rgba(129, 140, 248, 1),
      0 0 10px rgba(129, 140, 248, 1),
      0 0 20px rgba(129, 140, 248, 0.8),
      0 0 40px rgba(129, 140, 248, 0.6),
      0 0 80px rgba(129, 140, 248, 0.4);
  }
  
  .glow {
    text-shadow: 0 0 10px rgba(129, 140, 248, 0.5),
                 0 0 20px rgba(129, 140, 248, 0.3),
                 0 0 30px rgba(129, 140, 248, 0.2);
  }
  
  /* Border Effects */
  .cyberpunk-border {
    --border-width: 3px;
    --border-radius: 8px;
    position: relative;
    border-radius: var(--border-radius);
  }
  
  .cyberpunk-border::after {
    content: '';
    position: absolute;
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    border: var(--border-width) solid transparent;
    border-radius: var(--border-radius);
    background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background-size: 300% 300%;
    animation: borderGradient 4s ease alternate infinite;
    z-index: -1;
  }
  
  /* Interactive Effects */
  .hover-glow {
    transition: all 0.3s ease;
  }
  
  .hover-glow:hover {
    box-shadow: 
      0 0 5px rgba(129, 140, 248, 0.5),
      0 0 10px rgba(129, 140, 248, 0.4),
      0 0 15px rgba(129, 140, 248, 0.3),
      0 0 20px rgba(129, 140, 248, 0.2);
    transform: translateY(-2px);
  }
  
  /* ==========================================================================
     UI Components
     ========================================================================== */
  
  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #1a1a1a;
  }
  
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #4f46e5, #7c3aed);
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #4338ca, #6d28d9);
  }
  
  /* Dark Theme for Date/Time Picker */
  input[type="datetime-local"] {
    color-scheme: dark;
    background-color: #1f2937; 
    color: white;
    border-color: #374151;
    border-radius: 0.375rem;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  input[type="datetime-local"]::placeholder {
    color: rgba(156, 163, 175, 0.8);
  }

  input[type="datetime-local"]:focus {
    background-color: #111827;
    border-color: #4f46e5;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.3);
  }

    /* Calendar popup styling */
    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
      opacity: 0.9;
      cursor: pointer;
      position: absolute;
      right: 10px;
      transform: scale(1.2);
    }

    ::-webkit-calendar-picker-indicator:hover {
      opacity: 1;
      transform: scale(1.3);
    }

    /* Remove default styling on focus */
    input[type="datetime-local"]:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.3);
    }

    /* Webkit browsers specific styling */
    input::-webkit-datetime-edit {
      color: white;
    }

    input::-webkit-datetime-edit-fields-wrapper {
      color: white;
    }

    input::-webkit-datetime-edit-text {
      color: rgba(156, 163, 175, 1);
    }

    input::-webkit-datetime-edit-month-field,
    input::-webkit-datetime-edit-day-field,
    input::-webkit-datetime-edit-year-field,
    input::-webkit-datetime-edit-hour-field,
    input::-webkit-datetime-edit-minute-field,
    input::-webkit-datetime-edit-ampm-field {
      color: white;
    }

    /* Firefox specific styling */
    @-moz-document url-prefix() {
      input[type="datetime-local"] {
        color: white;
        background-color: #1f2937;
      }
    }
  /* ==========================================================================
     Global Layout
     ========================================================================== */
  
  html, body {
    overflow-x: hidden;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    max-width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Fix for elements that might cause horizontal scroll */
  .fixed, 
  .absolute {
    max-width: 100vw;
  }
  
  /* Fix for animation containers */
  .animate-gradient-x,
  .animate-pulse,
  .particle-container,
  .animated-background {
    overflow: hidden;
    max-width: 100vw;
  }
  
  /* Fix for floating elements */
  [class*="floating"],
  [class*="float"] {
    transform: translateZ(0);
    overflow: visible;
    max-width: 100vw;
  }
  
  /* Fix for grid layouts */
  .grid {
    width: 100%;
    max-width: 100%;
  }
  
  /* Container width controls */
  .max-w-7xl, 
  .max-w-6xl, 
  .max-w-5xl, 
  .max-w-4xl {
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Animation readiness class */
  .animations-ready * {
    will-change: auto !important;
  }
  
  /* ==========================================================================
     Responsive Adjustments & Performance Optimizations
     ========================================================================== */
  
  @media (max-width: 768px) {
    /* Disable animations for better performance */
    .floating, 
    .pulse, 
    .animate-gradient-x {
      animation: none !important;
    }
    
    /* Mobile layout adjustments */
    .interactive-bg {
      background-attachment: scroll !important;
    }
    
    .z-50 {
      z-index: 9999;
    }
  
    .grid-cols-1 > * {
      margin-bottom: 1rem;
    }
    
    .FloatingBox {
      position: relative;
      z-index: 30;
      margin-bottom: 2rem;
    }
    
    .grid-cols-1 {
      gap: 2rem;
    }
    
    #features {
      position: relative;
      z-index: 10;
      margin-bottom: 4rem;
    }
    
    /* Fix horizontal scrolling on mobile devices */
    .max-w-7xl, 
    .max-w-6xl, 
    .max-w-5xl, 
    .max-w-4xl {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    
    /* Force hide horizontal overflow on mobile */
    .overflow-x-auto {
      overflow-x: hidden !important;
    }
    
    /* Reduce complexity of effects */
    .glass-card {
      backdrop-filter: none; /* Improve performance */
    }
    
    .cyberpunk-border::after {
      animation: none; /* Disable border animation */
    }
  }
  
  /* ==========================================================================
     Performance Optimizations
     ========================================================================== */
  
  /* Use hardware acceleration where beneficial */
  .animate-fade-in,
  .floating,
  .rotate-360,
  .animate-gradient-x {
    will-change: transform, opacity;
    transform: translateZ(0); /* Force GPU acceleration */
  }
  
  /* Optimize repaints for animations */
  .glass-card,
  .glass {
    backface-visibility: hidden;
  }