body > iframe {
    display: none !important;
  }
  
  body > div:not([id]) {
    display: none !important;
  }
  
  #root ~ div[role="dialog"] {
    display: none !important;
  }
  
  div[style*="background-color: rgb(255, 223, 224)"] {
    display: none !important;
  }
  
  #react-error-overlay,
  #stackframe-error-overlay {
    display: none !important;
  }
  
  /* Hide React error elements */
  body > div:empty,
  body > div > div:first-child:not([id]):not([class]) {
    display: none !important;
  }
  
  .react-error-overlay {
    display: none !important;
  }